import moment from "moment"
import { DEFAULT_SERVER_DATE_FORMAT } from "@Platon/const"

/**
 * @param {PlatonTable} data
 * @return {*}
 */
export function transformTableMeta(data) {
	if (data && data.filters && Array.isArray(data.filters)) {
		for (let filter of data.filters) {
			if (filter.type === "date") {
				filter.defaultValue = moment(filter.defaultValue).format(DEFAULT_SERVER_DATE_FORMAT)
			}
		}
	}

	return data
}
