export default {
	data() {
		return {
			/**
			 * @param {PlatonTable} meta
			 */
			afterMetaLoaded(meta) {},

			/**
			 * @param {any[]} data
			 */
			afterDataLoaded(data) {},

			/**
			 * @param {Object} newFilters
			 * @param {Object} oldFilters
			 */
			onFiltersUpdating(newFilters, oldFilters) {
				return newFilters
			},

			transformTableData(response) {
				return response.data.results
			}
		}
	}
}
