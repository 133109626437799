<template>
    <div>
        <platon-link class="mr-2" :link="`/forms/tables/${table.id}?table_level=${tableLevel}&_target=modal`">
            <btn icon="fa fa-cog" variant="primary">
                {{ $l("platon.table_setup", "Созлаш") }}
            </btn>
        </platon-link>

        <platon-link
            class="mr-2"
            :link="`/forms/table_columns?table_name=${tableName}&table_level=${tableLevel}&_target=modal`"
        >
            <btn icon="fa fa-plus-circle" variant="success">
                {{ $l("platon.table_add_column", "Устун қўшиш") }}
            </btn>
        </platon-link>

        <platon-link
            class="mr-2"
            :link="`/tables/table_columns?table_name=${tableName}&table_level=${tableLevel}&_target=blank`"
        >
            <btn icon="fa fa-columns" variant="primary">
                {{ $l("platon.table_column_list", "Устунлар") }}
            </btn>
        </platon-link>

        <platon-link
            class="mr-2"
            :link="`/forms/table_filters?table_name=${tableName}&table_level=${tableLevel}&_target=modal`"
        >
            <btn icon="fa fa-plus-circle" variant="success">
                {{ $l("platon.table_add_filter", "Фильтр қўшиш") }}
            </btn>
        </platon-link>

        <platon-link
            class="mr-2"
            :link="`/tables/table_filters?table_name=${tableName}&table_level=${tableLevel}&_target=blank`"
        >
            <btn icon="fa fa-filter" variant="primary">
                {{ $l("platon.table_filter_list", "Фильтрлар") }}
            </btn>
        </platon-link>

        <platon-link
            class="mr-2"
            :link="`/forms/table_buttons?table_name=${tableName}&table_level=${tableLevel}&_target=modal`"
        >
            <btn icon="fa fa-plus-circle" variant="success">
                {{ $l("platon.table_add_button", "Тугма қўшиш") }}
            </btn>
        </platon-link>

        <platon-link class="mr-2" :link="`/tables/table_buttons?table_name=${tableName}&_target=blank`">
            <btn icon="fa fa-bars" variant="primary">
                {{ $l("platon.table_button_list", "Тугмалар") }}
            </btn>
        </platon-link>
    </div>
</template>

<script>
import Btn from "@Platon/components/extended/Btn.vue"

/**
 * @property {PlatonTable} table
 * @property {string} tableName
 * @property {number} tableLevel
 */
export default {
    name: "TableControls",
    components: { Btn },
    inject: ["tableName", "tableLevel"],

    props: {
        table: {}
    }
}
</script>
