<template>
    <dynamic-page-renderer v-show="isLoaded" :page="pageProperties" @pageLoaded="onPageLoaded" />
</template>
<script>
import DynamicPageRenderer from "@Platon/components/pages/DynamicPageRenderer.vue"

export default {
    name: "ComponentMaker",
    components: { DynamicPageRenderer },

    props: {
        name: {
            required: true,
            default: ""
        },
        content: {
            required: true,
            default: "<span></span>"
        }
    },

    data() {
        return {
            isLoaded: false
        }
    },

    methods: {
        onPageLoaded() {
            this.isLoaded = true
        }
    },

    computed: {
        pageProperties() {
            return {
                component: null,
                components: this.$projectInfo.components || [],
                content: this.content,
                css: null,
                data: {},
                id: 1,
                isPublic: false,
                js: null,
                name: `component-maker-${this.name}`,
                projectId: 0,
                title: "",
                vueCode: null
            }
        }
    }
}
</script>
