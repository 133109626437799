<template>
    <div
        class="d-inline-block pl-2 dropdown"
        @click.exact="show()"
        v-if="breadcrumbs.items.length + 1 >= index && allOptions.length > 0"
    >
        <i class="fa" :class="shown ? 'fa-caret-down' : 'fa-caret-right'"></i>

        <div class="dropdown-menu" :class="{ show: shown }">
            <div class="mx-2 my-1" v-if="allOptions.length >= 10">
                <b-form-input
                    size="sm"
                    @click.prevent.stop
                    :placeholder="$l('platon.search_dots', 'Излаш...')"
                    class="mb-2"
                    v-model="filterText"
                    ref="search"
                />
            </div>

            <div v-if="allOptions.length > 0 && options.length === 0">
                <div class="mx-3 mt-2 mb-1 text-nowrap">
                    {{ $l("platon.select_has_no_data", "Натижа топилмади") }}
                </div>
            </div>

            <div style="max-height: 400px; overflow-y: auto">
                <platon-link class="dropdown-item" :link="o.link" v-for="(o, i) in options" :key="i">
                    {{ $l(o.translate, o.text) }}
                </platon-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "TableBreadcrumbDropdown",

    props: {
        breadcrumbs: {},
        index: {
            type: Number,
            required: true
        }
    },

    data() {
        return {
            shown: false,
            filterText: null
        }
    },

    methods: {
        show() {
            if (this.shown) return

            this.shown = true

            setTimeout(() => {
                window.addEventListener(
                    "click",
                    () => {
                        this.shown = false
                    },
                    { once: true }
                )
            })
        }
    },

    computed: {
        level() {
            return this.index + 1
        },

        allOptions() {
            try {
                return this.breadcrumbs.options.filter((c) => c.level === this.level)
            } catch {
                return []
            }
        },

        options() {
            try {
                return this.allOptions.filter((c) => {
                    // filter by search
                    if (this.filterText && c.text) {
                        return c.text.toLowerCase().includes(this.filterText)
                    } else {
                        return true
                    }
                })
            } catch {
                return []
            }
        }
    },

    watch: {
        shown(val) {
            if (val) {
                if (this.$refs.search) {
                    this.$nextTick(() => {
                        this.$refs.search.focus()
                    })
                }
            }
        }
    }
}
</script>
