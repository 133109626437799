<template>
    <BaseControl class="mt-1" v-bind="$attrs">
        <date-picker
            :value="range"
            @input="updateRange"
            :lang="lang"
            range
            type="date"
            format="DD.MM.YYYY"
        ></date-picker>
    </BaseControl>
</template>

<script>
import BaseControl from "@Platon/components/form/controls/BaseControl.vue"
import DatePicker from "vue2-datepicker"
import "vue2-datepicker/index.css"
import { DEFAULT_SERVER_DATE_FORMAT } from "@Platon/const"
import "./DatePickerLocaleUz"
import "vue2-datepicker/locale/ru"
import { localeInfo } from "@Platon/core/translations"
import moment from "moment"

export default {
    name: "TableFilterDateRangePicker",
    components: {
        DatePicker,
        BaseControl
    },

    props: {
        value: {},
        item: {}
    },

    data() {
        return {
            range: this.parseDateFromValue(this.value),
            lang: localeInfo.locale
        }
    },

    methods: {
        /**
         * @param {String[]} range
         */
        parseDateFromValue(range) {
            if (!Array.isArray(range)) {
                return null
            }

            return range.map((d) => {
                let date = moment(d, DEFAULT_SERVER_DATE_FORMAT)

                if (!date.isValid()) {
                    return null
                }

                return date.toDate()
            })
        },

        /**
         * @param {Date[]} range
         */
        formatDate(range) {
            return range
                .filter((n) => n)
                .map((d) => {
                    return moment(d).format(DEFAULT_SERVER_DATE_FORMAT)
                })
        },

        updateRange(range) {
            this.range = range
            this.$emit("input", this.formatDate(range))
        }
    },

    watch: {
        value: {
            handler(newRange) {
                this.range = this.parseDateFromValue(newRange)
            }
        }
    }
}
</script>

<style></style>
