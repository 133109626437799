import DatePicker from "vue2-datepicker"

const lang = {
	formatLocale: {
		months: [
			"январ",
			"февраль",
			"март",
			"апрел",
			"май",
			"июн",
			"июл",
			"август",
			"сентябр",
			"октябр",
			"ноябр",
			"декабр"
		],
		monthsShort: ["янв", "февр", "мар", "апр", "май", "июн", "июл", "авг", "сен", "окт", "ноя", "дек"],
		weekdays: ["Якшанба", "Душанба", "Сешанба", "Чоршанба", "Пайшанба", "Жума", "Шанба"],
		weekdaysShort: ["Якш", "Душ", "Сеш", "Чор", "Пай", "Жум", "Шан"],
		weekdaysMin: ["Як", "Ду", "Се", "Чо", "Па", "Жу", "Ша"],
		firstDayOfWeek: 1,
		firstWeekContainsDate: 1
	},
	yearFormat: "YYYY",
	monthFormat: "MMM",
	monthBeforeYear: true
}

const la = {
	formatLocale: {
		months: [
			"Yanvar",
			"Fevral",
			"Mart",
			"Aprel",
			"May",
			"Iyun",
			"Iyul",
			"Avgust",
			"Sentyabr",
			"Oktyabr",
			"Noyabr",
			"Dekabr"
		],
		monthsShort: ["Yanv", "Fev", "Mar", "Apr", "May", "Iyun", "Iyul", "Avg", "Sen", "Okt", "Noy", "Dek"],
		weekdays: ["Yakshanba", "Dushanba", "Seshanba", "Chorshanba", "Payshanba", "Juma", "Shanba"],
		weekdaysShort: ["Yak", "Dush", "Sesh", "Chor", "Pay", "Jum", "Shan"],
		weekdaysMin: ["Yak", "Du", "Se", "Cho", "Pa", "Ju", "Sha"],
		firstDayOfWeek: 1,
		firstWeekContainsDate: 1
	},
	yearFormat: "YYYY",
	monthFormat: "MMM",
	monthBeforeYear: true
}

DatePicker.locale("uz", lang)
DatePicker.locale("la", la)

export default { lang, la }
