<template>
    <BaseControl v-bind="$attrs" :errors="combinedErrors">
        <tree-select
            :normalizer="normalizer"
            valueFormat="object"
            :loadOptions="loadOptions"
            :options="options"
            :clearable="allowClear"
            v-model="local"
            :placeholder="item.placeholder || ''"
            :noOptionsText="$l('platon.select_has_no_data', 'Натижа топилмади')"
            :noResultsText="$l('platon.select_has_no_data', 'Натижа топилмади')"
        />
    </BaseControl>
</template>

<script>
import { localeInfo } from "@Platon/core/translations"
import BaseControl from "@Platon/components/form/controls/BaseControl.vue"
import InputControl2Mixin from "@Platon/mixins/InputControl2Mixin"

/**
 * @typedef TreeNode
 * @property {number|string} id
 * @property {string} name
 * @property {?string} label
 * @property {string[]} paths
 * @property {boolean} has_children
 * @property {?boolean} isDefaultExpanded
 * @property {?TreeNode[]} children
 */

export default {
    name: "TreeSelectFilter",
    components: { BaseControl },

    mixins: [InputControl2Mixin],

    props: {
        item: {},
        value: {}
    },

    data() {
        return {
            options: []
        }
    },
    inject: ["table"],
    computed: {
        local: {
            get() {
                if (!this.value || typeof this.value !== "string") return null
                const paths = this.value.split(",")
                const findOption = (node, paths, index) => {
                    if (node && node.children && paths.length > index) {
                        const n = node.children.find((item) => item.id == paths[index])
                        if (!n) return null
                        return findOption(n, paths, index + 1)
                    }
                    return node
                }

                return findOption({ children: this.options }, paths, 0)
            },

            set(value) {
                if (!value) this.$emit("input", value)
                const id = value.paths.join(",")
                this.$emit("input", id)
            }
        },

        allowClear() {
            if (typeof this.item.allowClear === "boolean") {
                return this.item.allowClear
            }
            return false
        }
    },
    async mounted() {
        const options = this.parseData(this.item.data)
        await this.getSelectedOptionChildren(options)
        this.options = options
    },
    methods: {
        async getSelectedOptionChildren(options) {
            if (this.value && typeof this.value === "string") {
                const paths = this.value.split(",")
                if (paths.length > 1) {
                    await this.getChildrenOptions(options, paths, 0)
                }
            }
        },
        /**
         * @param options {TreeNode[]}
         * @param paths {string[]}
         * @param index {number}
         */
        async getChildrenOptions(options, paths, index) {
            if (paths.length < index + 1 || !options.length) return
            const parent = this.findNodeAmongChildrenById(options, paths[index])
            if (parent) {
                parent.children = await this.getChildren(parent)
                const nextParent = this.findNodeAmongChildrenById(parent.children, paths[index + 1])
                if (nextParent) {
                    await this.getChildrenOptions(nextParent.children, paths, index + 1)
                }
            }
        },
        /**
         *
         * @param children {TreeNode[]}
         * @param id {string}
         * @returns {TreeNode|null}
         */
        findNodeAmongChildrenById(children, id) {
            if (!children && !Array.isArray(children)) return null
            return children.find((item) => item.id == id)
        },
        /**
         * @param node {TreeNode}
         */
        normalizer(node) {
            return {
                ...node,
                id: node.paths.join(","),
                name: this.translateOption(node)
            }
        },
        /**
         * @param options {TreeNode[]}
         * @return {TreeNode[]}
         */
        parseData(options) {
            return options.map((item) => {
                this.setDeepValues(item)
                return { ...item }
            })
        },

        /**
         *
         * @param node {TreeNode}
         * @param parent {TreeNode}
         * @return {TreeNode}
         */
        setDeepValues(node, parent) {
            node.paths = []
            if (parent) node.paths = [...parent.paths]
            node.label = node.name
            node.paths.push(node.id)
            if (node.children) {
                node.children.forEach((i) => this.setDeepValues(i, node))
            } else {
                node.children = node.has_children ? null : false
            }
            return node
        },
        /**
         *
         * @param parent {TreeNode}
         * @param level {number}
         */
        async loadChild(parent, level) {
            const { tableName } = this.table
            const { key } = this.item
            const { data } = await this.$http.post(`tables/${tableName}/${key}/select-list`, {
                parentId: parent.id,
                selectLevel: level
            })
            return data
        },
        /**
         * @param parent {TreeNode}
         */
        async getChildren(parent) {
            parent.isDefaultExpanded = true
            let items = await this.loadChild(parent, parent.paths.length)
            items.forEach((item) => {
                this.setDeepValues(item, parent)
            })
            return items
        },

        /**
         * @param action {'LOAD_CHILDREN_OPTIONS' | 'LOAD_ROOT_OPTIONS' | 'ASYNC_SEARCH'}
         * @param callback {Function}
         * @param parentNode {TreeNode}
         */
        async loadOptions({ action, callback, parentNode }) {
            if (action === "LOAD_CHILDREN_OPTIONS") {
                parentNode.children = await this.getChildren(parentNode)
                callback()
            }
        },
        /**
         * @param el {TreeNode}
         * @return {string}
         */
        translateOption(el) {
            return el["name_" + localeInfo.locale] || el.name
        }
    }
}
</script>
